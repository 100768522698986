import React from 'react';
import './DomainForSale.css';

const App = () => {
  const email = "jaiswalkishan577@gmail.com"; // Replace with your email
  const secondemail = "Shivamtheastralguy@gmail.com"; // Replace with your email

 // const phone = "+91 "; // Replace with your phone number
  const domain = "Rainmatter.in"; // Replace with your domain name

  return (
    <div className="domain-container">
      <header className="domain-header">
        <h1>For Sale</h1>
      </header>
      <main className="domain-content">
        <h2 className="domain-name">{domain}</h2>
        <p className="domain-info">
          Unlock the potential of this premium domain to enhance your brand’s digital presence.
        </p>
        <div className="contact-section">
          <h3>Get in Touch</h3>
          <p>Primary Email: <a href={`mailto:${email}`} className="contact-link">{secondemail}</a></p>
          <p>Secondary Email: <a href={`mailto:${email}`} className="contact-link">{email}</a></p>

          {/* <p>Phone: <a href={`tel:${phone}`} className="contact-link">{phone}</a></p> */}
        </div>
      </main>
      {/* <footer className="domain-footer">
        <p>Secure your digital future today.</p>
      </footer> */}
    </div>
  );
};

export default App;
